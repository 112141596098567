<template>
  <mp-box v-if="widgetIsShown">
    <mp-tooltip label="Customer Support" position="left">
      <mp-box
        position="fixed"
        right="0px"
        bottom="20px"
        background="#4fb262"
        color="#fff"
        py="4px"
        px="6px"
        border-radius="0.2em 0em 0em 0.2em"
        cursor="pointer"
        z-index="10"
        @click="redirectToCustomerCare"
      >
        <mp-icon name="WhatsApp" size="md" />
      </mp-box>
    </mp-tooltip>
  </mp-box>
</template>
<script>
export default {
  props: {
    isTrialUser: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      widgetIsShown: true,
    }
  },
  mounted() {
    window.addEventListener(
      'message',
      (event) => {
        if (event.data.type === 'chatbot-whatsapp-icon')
          this.widgetIsShown = event.data.data
      },
      false
    )
  },
  methods: {
    redirectToCustomerCare() {
      if (this.isTrialUser) {
        window.open(
          'https://wa.me/6281287090263?&text=[Q4H-02K]%20Halo,%20saya%20sudah%20menggunakan%20akun%20trial%20dan%20ingin%20berlangganan%20Omnichanel%20Qontak'
        )
      } else {
        window.open('https://app.qontak.com/contact-support')
      }
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@/assets/stylesheets/abstracts/_variables.scss';

.mp-box__customer-support {
  width: 36px;
  height: 32px;
  position: fixed;
  bottom: 20px;
  right: 0px;
  background-color: $q-success;
  padding: 4px 6px;
  font-size: 24px;
  color: #fff;
  border-radius: 0.2em 0em 0em 0.2em;
  cursor: pointer;
  transition: 1s;
  z-index: 10;

  &.hidden {
    right: -40px;
    transition: 1s;
  }
}
</style>
