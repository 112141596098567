<template>
  <mp-pseudo-box
    as="div"
    to="#"
    role="group"
    display="flex"
    border-radius="sm"
    padding="2"
    min-height="20"
    transition="all .2s ease"
  >
    <mp-grid as="div" w="100%" template-columns="repeat(6, 1fr)" gap="6">
      <mp-grid-item col-span="1">
        <mp-pseudo-box
          border="1px"
          border-color="gray.50"
          border-radius="md"
          padding="2"
          :_group-hover="{
            backgroundColor: 'white',
          }"
        >
          <mp-icon :name="icon" />
        </mp-pseudo-box>
      </mp-grid-item>
      <mp-grid-item col-span="4">
        <mp-flex flex-direction="column" flex="1">
          <mp-text font-size="md" font-weight="semibold">
            {{ title }}
          </mp-text>
          <mp-text font-size="md" color="gray.600">
            {{ description }}
          </mp-text>
        </mp-flex>
      </mp-grid-item>
      <mp-grid-item col-span="1">
        <mp-box @click="openApps">
          <mp-icon
            name="newtab"
            color="gray.600"
            width="12px"
            height="12px"
            cursor="pointer"
          />
        </mp-box>
      </mp-grid-item>
    </mp-grid>
  </mp-pseudo-box>
</template>

<script>
export default {
  name: 'SwitchAppItem',
  props: {
    title: {
      type: String,
      default: () => '',
    },
    description: {
      type: String,
      default: () => '',
    },
    icon: {
      type: String,
      default: () => '',
    },
    link: {
      type: String,
      default: () => '#',
    },
  },
  methods: {
    openApps() {
      window.open(this.link)
    },
  },
}
</script>
