<template>
  <mp-drawer :is-open="isSwitchAppOpen" :on-close="onSwitchAppToggle">
    <mp-drawer-overlay />
    <mp-drawer-content
      max-width="xs"
      border="none"
      border-left="1px"
      border-left-color="gray.100"
    >
      <mp-drawer-close-button />
      <mp-box
        margin-top="16"
        max-height="calc(100vh)"
        overflow-y="auto"
        overflow-x="hidden"
      >
        <mp-box padding-x="6">
          <mp-text margin-bottom="2">
            Hi {{ $auth.user.full_name }}, your apps are listed here.
          </mp-text>
          <SwitchAppItem
            title="Mekari Qontak"
            description="Complete CRM software"
            icon="qontak-brand"
            link="https://qontak.com/?utm_source=ecosystem&utm_medium=+qontak+%28in-app%29&utm_campaign=qontak+%28in-app%29+-+product+selector"
          />
          <mp-divider margin-top="2" margin-bottom="2.5" />
          <mp-text margin-bottom="2"
            >Other apps by Mekari that might suit your business needs.</mp-text
          >
          <SwitchAppItem
            title="Mekari Talenta"
            description="Advanced payroll automation & HR solution"
            icon="talenta-brand"
            link="https://www.talenta.co/?utm_source=ecosystem&utm_medium=+qontak+%28in-app%29&utm_campaign=qontak+%28in-app%29+-+product+selector"
          />
          <SwitchAppItem
            title="Mekari Jurnal"
            description="Integrated online accounting software"
            icon="jurnal-brand"
            link="https://www.jurnal.id/id/?utm_source=ecosystem&utm_medium=+qontak+%28in-app%29&utm_campaign=qontak+%28in-app%29+-+product+selector"
          />
          <SwitchAppItem
            title="Mekari Klikpajak"
            description="Simple tax management platform"
            icon="klikpajak-brand"
            link="https://klikpajak.id/?utm_source=ecosystem&utm_medium=+qontak+%28in-app%29&utm_campaign=qontak+%28in-app%29+-+product+selector"
          />
          <SwitchAppItem
            title="Mekari Flex"
            description="Flexible employee benefits management"
            icon="flex-brand"
            link="https://mekari.com/produk/mekari-flex/?utm_source=ecosystem&utm_medium=+qontak+%28in-app%29&utm_campaign=talenta+%28in-app%29+-+product+selector"
          />
          <SwitchAppItem
            title="Mekari e-Sign"
            description="Digital signature app"
            icon="sign-brand"
            link="https://mekarisign.com/?utm_source=ecosystem&utm_medium=+qontak+%28in-app%29&utm_campaign=qontak+%28in-app%29+-+product+selector"
          />
          <mp-divider margin-top="2" margin-bottom="2.5" />
          <mp-text margin-bottom="2">More from Mekari</mp-text>
          <SwitchAppItem
            title="Mekari University"
            description="Professional learning platform"
            icon="university-brand"
            link="https://univ.mekari.com/?utm_source=ecosystem&utm_medium=+qontak+%28in-app%29&utm_campaign=qontak+%28in-app%29+-+product+selector"
          />
          <mp-box align="center">
            <mp-button variant="outline" mt="3" @click="openMekariMarketplace">
              More from Mekari Marketplace
            </mp-button>
          </mp-box>
        </mp-box>
      </mp-box>
    </mp-drawer-content>
  </mp-drawer>
</template>

<script>
import {
  MpBox,
  MpText,
  MpDivider,
  MpDrawer,
  MpDrawerOverlay,
  MpDrawerContent,
  MpDrawerCloseButton,
} from '@mekari/pixel'
import { mapState } from 'vuex'
import SwitchAppItem from '~/components/layouts/SwitchAppItem'

export default {
  name: 'SwitchApp',
  components: {
    MpBox,
    MpText,
    MpDivider,
    MpDrawer,
    MpDrawerOverlay,
    MpDrawerContent,
    MpDrawerCloseButton,
    SwitchAppItem,
  },
  props: {
    isSwitchAppOpen: {
      type: Boolean,
      default: false,
    },
    onSwitchAppToggle: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    ...mapState('preferences', ['billing_info']),
  },
  methods: {
    openMekariMarketplace() {
      // Store metric to mixpanel
      this.storeMixpanelMetric('Marketplace More Action', {
        Role: this.$auth.user.role,
        Email: this.$auth.user.email,
        'User ID': this.$auth.user.id,
        'Company ID': this.getOrganizationForMetric()['Company ID'],
        'Company Name': this.getOrganizationForMetric()['Company Name'],
        Trial: this.billing_info.is_trial,
      })
      window.open(
        `${process.env.MEKARI_LAUNCHPAD_URL}/marketplace?ref=qontak-chat-product-selector`
      )
    },
  },
}
</script>
